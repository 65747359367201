<template>
  <v-app>
    <!--pages-core-app-bar /-->
    <pages-core-view />
    <base-material-snackbar
      v-model="snackbar.state"
      :timeout="6000"
      :type="snackbar.color"
      v-bind="{
        [parsedDirection[0]]: true,
        [parsedDirection[1]]: true
      }"
    >
      <div class="d-flex justify-space-between">
        <div class="mr-6">
          {{ snackbar.text }}
        </div>
        <v-btn
          x-small
          class="v-alert__dismissible"
          fab
          color="white"
          @click="snackbar.state = false"
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </base-material-snackbar>
    <!--pages-core-footer /-->
  </v-app>
</template>

<script>
  export default {
    name: 'PagesIndex',

    components: {
      // PagesCoreAppBar: () => import('./components/core/AppBar'),
      // PagesCoreFooter: () => import('./components/core/Footer'),
      PagesCoreView: () => import('./components/core/View'),
    },

    computed: {
      parsedDirection () {
        return this.snackbar.direction.split(' ')
      },

      snackbar: {
        get () {
          return this.$store.state.snackbar
        },
        set (val) {
          this.$store.commit('setSnackbar', val)
        },
      },
    },

    created () {
      this.$vuetify.theme.dark = true
    },

    beforeDestroy () {
      this.$vuetify.theme.dark = false
    },
  }
</script>
